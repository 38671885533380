export default {
    steps: {
        'annuity' : {
            order:1,
            id: 'annuity',
            title: 'So what is an Annuity?',
            description: 'An introduction to these financial products that are designed to provide lifetime income streams.',
            link: 'education.annuity',
            
            video_id: '3jnkizhngu',
            slug: 'so-what-is-an-annuity',
            quiz_question: null,
            quiz_answer_choices: null,
            quiz_answer_index: null,
            quiz_answer_explanation: null,
            minutes_to_complete: 5,
        },
        'basic-annuity' : {
            order:2,
            id: 'basic-annuity',
            title: 'Basic Annuity Terms',
            description: 'Before engaging with an annuity, let\'s review some commonly used terms.',
            link: 'education.basic-annuity',

            video_id: 'nms2wkzehw',
            slug: 'introduction-to-the-fixed-index-annuity',
            quiz_question: 'Joe purchased a Midland National fixed index annuity 2 years ago and then passed away. What is the death benefit amount that is available to his beneficiaries?',
            quiz_answer_choices: ['The premium that Joe put into the annuity.', 'The full accumulation value at the time of his death.'],
            quiz_answer_index: 1,
            quiz_answer_explanation: 'The Death Benefit equals at least the accumulation value, but state law may require it to be higher if the accumulation value doesn’t meet a certain minimum required by state law.',
            quiz_incorrect_answer_explanation: null,
            minutes_to_complete: 8,
        },
        'regular-income' : {
            order:3,
            id: 'regular-income',
            title: 'Receiving Regular Income From Your Annuity',
            description: 'All about the flexibility that annuities provide.',
            link: 'education.regular-income',

            video_id: '2ngo6g5nd5',
            slug: 'ensuring-you-never-run-out',
            quiz_question: 'What value does the income rider provide to you?',
            quiz_answer_choices: ['It guarantees me a monthly lifetime income stream', 'It allows me to withdraw the income rider value in a lump sum at any time'],
            quiz_answer_index: 0,
            quiz_answer_explanation: 'In addition to a monthly guaranteed lifetime income stream, it allows you to take penalty free withdrawals prior to electing the lifetime payment amount, the accumulation value has the opportunity to earn interest and in the event of your death pass on any remaining accumulation value to beneficiaries',
            quiz_incorrect_answer_explanation: 'The income rider value is not available for withdrawal as a lump sum benefit.',
            minutes_to_complete: 6,
        },
        'receive-money' : {
            order:4,
            id: 'receive-money',
            title: 'Other ways to receive money from your annuity',
            description: 'All about the flexibility that annuities provide.',
            link: 'education.receive-money',

            video_id: 'g2kg988fv7',
            slug: 'other-ways-to-receive-money',
            quiz_question: 'Both annuitization and the income rider can offer guaranteed income for life. Which offers the flexibility to have access to your cash value once payments start?',
            quiz_answer_choices: ['Annuitization', 'Income Rider'],
            quiz_answer_index: 1,
            quiz_answer_explanation: 'The income rider ensures that you will receive guaranteed lifetime income payments, continue to earn interest, and pass on the remainder to your beneficiaries in the option that they choose either a lump sum or annuity payments',
            quiz_incorrect_answer_explanation: 'Annuitization payments are irrevocable once elected and do not allow access to your annuity’s cash value.',
            minutes_to_complete: 5,
        },
        'two-special' : {
            order:5,
            id: 'two-special',
            title: 'Two Special Withdrawal Situations',
            description: 'Learn about early withdrawal and excess withdrawal.',
            link: 'education.two-special',

            video_id: 'msl9mufiql',
            slug: 'early-surrender',
            quiz_question: 'In which situation would surrender charges apply?',
            quiz_answer_choices: ['Mary purchases an annuity that allows her to withdraw 10% penalty free each year. She withdraws 7% of the accumulation value of her annuity in the 7th year of her annuity', 'Mary withdraws the entire Accumulation Value from her annuity in the 2nd contract year'],
            quiz_answer_index: 1,
            quiz_answer_explanation: 'Surrender Charges apply when a withdrawal exceeds the penalty free withdrawal amount in the contract year. Early surrenders may also have a market value adjustment applied.',
            quiz_incorrect_answer_explanation: 'Surrender charges only apply if your withdrawal exceeds 10% of the accumulation value in the contract year.',
            minutes_to_complete: 4,
        },

    }
}