<template>
  <div class="page_content_wrap">
    <div class="annuities_wrap py-5">
      <div class="container">
        <div class="annuities_you_box">
          <h3 class="text-dark mb-4 text-center">
            Select the annuities you’d like to learn about
          </h3>
          <form action="">
            <div class="form-group mb-3">
              <div
                class="checkbox_btn_with_txt btn-group-toggle"
                data-toggle="buttons"
              >
                <div class="row mb-3">
                  <div class="col-lg-4 col-md-6 mb-3">
                    <label class="btn btn-outline-primary text-left">
                      <input type="checkbox" id="retirement_concern_1" />
                      <div class="header-inner bg-gray-200">
                        <h6>MNL Endeavor 12</h6>
                      </div>
                      <div class="content-inner p-3">
                        <p class="font-size-sm">
                          This annuity is offeres through Midland National Life
                          Insurance Company, an A+ rated company.
                        </p>
                        <p class="font-size-sm">
                          This annuity is designed to achieve lifrtime income
                          while protecting against downside market risk.
                        </p>
                      </div>
                      <div class="list-inner-section provides px-3">
                        <p class="font-size-sm">
                          Here's how the Endeavor annuity accomplishes this:
                        </p>
                        <div class="icon-list position-relative">
                          <div class="icon position-absolute left-0 top-0">
                            <strong
                              class="fal fa-check-circle text-primary font-weight-bold"
                            ></strong>
                          </div>
                          <div class="icon-content pl-4">
                            <p class="font-size-sm">
                              The Endeavor offers a rider which pprovides
                              guaranteed lifetime income.
                            </p>
                          </div>
                        </div>
                        <div class="icon-list position-relative">
                          <div class="icon position-absolute left-0 top-0">
                            <strong
                              class="fal fa-check-circle text-primary font-weight-bold"
                            ></strong>
                          </div>
                          <div class="icon-content pl-4">
                            <p class="font-size-sm">
                              The Endeavor offers a rider which pprovides
                              guaranteed lifetime income.
                            </p>
                          </div>
                        </div>
                        <div class="icon-list position-relative">
                          <div class="icon position-absolute left-0 top-0">
                            <strong
                              class="fal fa-check-circle text-primary font-weight-bold"
                            ></strong>
                          </div>
                          <div class="icon-content pl-4">
                            <p class="font-size-sm">
                              The Endeavor offers a rider which pprovides
                              guaranteed lifetime income.
                            </p>
                          </div>
                        </div>
                        <div class="company-logo p-3">
                          <img
                            class="w-100"
                            src="/images/midland-national-life-logo.png"
                            alt="logo"
                          />
                        </div>
                      </div>
                      <div class="more-btn">
                        <a href="#" class="provides-button">More</a>
                      </div>
                    </label>
                  </div>
                  <div class="col-lg-4 col-md-6 mb-3">
                    <label class="btn btn-outline-primary text-left">
                      <input type="checkbox" id="retirement_concern_1" />
                      <div class="header-inner bg-gray-200">
                        <h6>MNL Endeavor 12</h6>
                      </div>
                      <div class="content-inner p-3">
                        <p class="font-size-sm">
                          This annuity is offeres through Midland National Life
                          Insurance Company, an A+ rated company.
                        </p>
                        <p class="font-size-sm">
                          This annuity is designed to achieve lifrtime income
                          while protecting against downside market risk.
                        </p>
                      </div>
                      <div class="list-inner-section endeavor px-3">
                        <p class="font-size-sm">
                          Here's how the Endeavor annuity accomplishes this:
                        </p>
                        <div class="icon-list position-relative">
                          <div class="icon position-absolute left-0 top-0">
                            <strong
                              class="fal fa-check-circle text-primary font-weight-bold"
                            ></strong>
                          </div>
                          <div class="icon-content pl-4">
                            <p class="font-size-sm">
                              The Endeavor offers a rider which pprovides
                              guaranteed lifetime income.
                            </p>
                          </div>
                        </div>
                        <div class="icon-list position-relative">
                          <div class="icon position-absolute left-0 top-0">
                            <strong
                              class="fal fa-check-circle text-primary font-weight-bold"
                            ></strong>
                          </div>
                          <div class="icon-content pl-4">
                            <p class="font-size-sm">
                              The Endeavor offers a rider which pprovides
                              guaranteed lifetime income.
                            </p>
                          </div>
                        </div>
                        <div class="icon-list position-relative">
                          <div class="icon position-absolute left-0 top-0">
                            <strong
                              class="fal fa-check-circle text-primary font-weight-bold"
                            ></strong>
                          </div>
                          <div class="icon-content pl-4">
                            <p class="font-size-sm">
                              The Endeavor offers a rider which pprovides
                              guaranteed lifetime income.
                            </p>
                          </div>
                        </div>
                        <div class="company-logo p-3">
                          <img
                            class="w-100"
                            src="/images/midland-national-life-logo.png"
                            alt="logo"
                          />
                        </div>
                      </div>
                      <div class="more-btn">
                        <a href="#" class="endeavor-button">More</a>
                      </div>
                    </label>
                  </div>
                  <div class="col-lg-4 col-md-6 mb-3">
                    <label class="btn btn-outline-primary text-left">
                      <input type="checkbox" id="retirement_concern_1" />
                      <div class="header-inner bg-gray-200">
                        <h6>MNL Endeavor 12</h6>
                      </div>
                      <div class="content-inner p-3">
                        <p class="font-size-sm">
                          This annuity is offeres through Midland National Life
                          Insurance Company, an A+ rated company.
                        </p>
                        <p class="font-size-sm">
                          This annuity is designed to achieve lifrtime income
                          while protecting against downside market risk.
                        </p>
                      </div>
                      <div class="list-inner-section insurance px-3">
                        <p class="font-size-sm">
                          Here's how the Endeavor annuity accomplishes this:
                        </p>
                        <div class="icon-list position-relative">
                          <div class="icon position-absolute left-0 top-0">
                            <strong
                              class="fal fa-check-circle text-primary font-weight-bold"
                            ></strong>
                          </div>
                          <div class="icon-content pl-4">
                            <p class="font-size-sm">
                              The Endeavor offers a rider which pprovides
                              guaranteed lifetime income.
                            </p>
                          </div>
                        </div>
                        <div class="icon-list position-relative">
                          <div class="icon position-absolute left-0 top-0">
                            <strong
                              class="fal fa-check-circle text-primary font-weight-bold"
                            ></strong>
                          </div>
                          <div class="icon-content pl-4">
                            <p class="font-size-sm">
                              The Endeavor offers a rider which pprovides
                              guaranteed lifetime income.
                            </p>
                          </div>
                        </div>
                        <div class="icon-list position-relative">
                          <div class="icon position-absolute left-0 top-0">
                            <strong
                              class="fal fa-check-circle text-primary font-weight-bold"
                            ></strong>
                          </div>
                          <div class="icon-content pl-4">
                            <p class="font-size-sm">
                              The Endeavor offers a rider which pprovides
                              guaranteed lifetime income.
                            </p>
                          </div>
                        </div>
                        <div class="company-logo p-3">
                          <img
                            class="w-100"
                            src="/images/midland-national-life-logo.png"
                            alt="logo"
                          />
                        </div>
                      </div>
                      <div class="more-btn">
                        <a href="#" class="insurance-button">More</a>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="guaranteed_section pb-5">
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="guaranteed_inner text-center text-dark pb-4">
              <h3>Guaranteed monthly income: 101</h3>
              <p>Learning about your options</p>
            </div>
          </div>
        </div>
        <div class="guaranteed_inner-loop">
          <div
            class="guaranteed_inner_box border border-2 mb-4"
            :key="key"
            v-for="(step, key) in steps"
          >
            <router-link
              :to="{ name: step.link }"
              class="text-dark text-decoration-none p-4 d-block"
            >
              <div class="row">
                <div class="col-lg-9 col-md-12">
                  <div class="guaranteed_inner-left pt-3 pr-3">
                    <h5>{{ step.title }}</h5>
                    <p class="font-size-sm">
                      {{ step.description }}
                    </p>
                  </div>
                </div>
                <div class="col-lg-3 col-md-12">
                  <div class="progress-box p-3">
                    <p class="text-muted text-right font-size-sm mb-0">
                      60% Completed
                    </p>
                    <div class="progress mb-2 mt-1">
                      <div
                        class="progress-bar w-30"
                        role="progressbar"
                        aria-valuenow="60"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <p class="text-muted text-right font-size-sm mb-0">
                      5 minutes remaining
                    </p>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="plannin_wrap bg-darkblue py-7">
      <div class="container">
        <div class="mt-3 text-center text-white">
          <p class="font-size-sm mb-2">Next Steps</p>
          <h2 class="mb-3">Discuss this with a Heyday Consultant</h2>
          <h6 class="mb-3">
            Our team has helped hundreds secure reliable income throughout
            retirement for over 20 years.
          </h6>
          <h1>
            <a class="text-white mb-3" href="tel:1-844-4-0000"
              ><i class="fas fa-phone-volume mr-2"></i>1-844-4-HEYDAY</a
            >
          </h1>
          <p class="">Weekdays, 8 AM - 7 PM (ET)</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import education from "@/data/education-details";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { getContact, updateContact } from '@/utils/firebase'
import QUESTIONS from '@/data/personal-details-questions'
import { parse } from 'query-string'

export default {
  name: 'Screen4',
  data() {
    return {
    steps: education.steps,
		concerns: QUESTIONS.my_top_retirement_concerns.options,
		isLoading: true,
      	isSubmitting: false,
      	errors: []
    };
  },
  mounted() {
	this.isLoading = true;
    this.getEducationProgress();
    // let question = Object.keys(QUESTIONS);
    // question.push('progressions');
    // console.log('QUESTIONS');
    // console.log(question);
    // getContact(question).then(response => {
    //   this.isLoading = false;
    //   let prop = response.data.properties;
    //   console.log(response.data.properties);
    //   this.SET_SURVEY(prop);
      
    // }).catch(error => {
    //   this.isLoading = false;
    //   console.log('error');
    //   console.log(error);
    // });
  },
  computed: {
    ...mapGetters("wizard", {
      survey: "getSurvey"
    })
  },
  methods: {
    ...mapActions("wizard", {
      updateProgress: "updateProgress",
      getEducationProgress: "getEducationProgress",
    }),
    ...mapMutations('wizard', ['SET_SURVEY', 'UPDATE_SURVEY_FIELD']),

    allCompleted(){
      this.props.educationProgressions.every(
        ({ understandingSignedOff }) => !!understandingSignedOff,
      );
    },
    anyUnderstandingExpired() {
       this.props.educationProgressions.some(
      ({ understandingExpired }) => understandingExpired
      );

    },
    checkForm(){
      this.errors = [];

      if (this.survey.my_top_retirement_concerns == '') {
        this.errors.push("Concern required.");
      }

    //   if (this.survey.are_you_retired == '') {
    //     this.errors.push("Work status required.");
	//   }
	  
      if (this.errors.length) {
        return false;
      } else {
        return true;
      }
    },
    submitWizard(e) {
		
		let values = {
			marital_status: this.survey.marital_status,
			are_you_retired: this.survey.are_you_retired,
			retirement_age: this.survey.retirement_age,
			age: this.survey.age,
			my_top_retirement_concerns: this.survey.my_top_retirement_concerns,
			industry: this.survey.industry,
			personal_finances_attitude: this.survey.personal_finances_attitude,
		}

		if(this.checkForm()) {
			this.isSubmitting = true;

			updateContact(values)
			.then((response) => {
				this.updateProgress(1);
				const queryParams = parse(location.search)
				if (queryParams['from-review']) {
					this.$router.push({ name : 'risk.review'});
				} else {
					this.$router.push({ name : 'risk.assessmentOverview'});
				}
			})
			.catch((error) => {
			console.log(error);
			this.isSubmitting = false;
			alert('Something went wrong!')
			})
		}

      	e.preventDefault();
    }
  }
};
</script>
